import React from "react"

import Layout from "../components/layout"
import Meta from "../components/meta"
import { Container } from "react-bootstrap"

export default () => (
  <Layout>
    <Meta title={"Impressum"} noindex/>
    <Container className={"section"}>
      <h1>Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Dorakami Coaching<br/>
        Christoph Berndt<br/>
        Weizenkamp 1<br/>
        59872 Meschede<br/>
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 (0) 160 / 987 492 16<br/>
        E-Mail: <a href="mailto:hallo@dorakami.de">hallo@dorakami.de</a>
      </p>

      <h2 className={"sub-section"}>Disclaimer/Haftungsausschluss</h2>
      <h3>Haftung für Inhalte</h3>
      <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
      <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
      <h3>Haftung für Links</h3>
      <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
        verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
        Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
      <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
        umgehend entfernen.</p>
      <h3>Urheberrecht</h3>
      <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
      <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
      <p>Quelle: <a target="_blank" rel="noopener noreferrer" href="https://www.e-recht24.de">e-recht24.de</a></p>

      <h2 className={"sub-section"}>Bildquellen</h2>
      <p>
        Luftaufnahme einer Straße im Wald von <a target="_blank" rel="noopener noreferrer"
                                                 href="https://unsplash.com/@ventiviews">Cameron Venti</a><br/>
        Silhouette einer Hand von <a target="_blank" rel="noopener noreferrer"
                                     href="https://unsplash.com/@victorfreitas">Victor Freitas</a><br/>
        Luftaufnahme einer Straßenkreuzung von <a target="_blank" rel="noopener noreferrer"
                                                  href="https://unsplash.com/@yiranding">Yiran Ding</a><br/>
        Mitgliedslogo des DVNLP vom <a target="_blank" rel="noopener noreferrer"
                                       href="https://www.dvnlp.de/">DVNLP e.V.</a><br/>
        Logo des Kampfkunst Kollegiums von der <a target="_blank" rel="noopener noreferrer"
                                                  href="https://www.kampfkunstkollegium.com/">Kampfkunst Kollegium GmbH
        & Co. KG</a>
      </p>
    </Container>
  </Layout>
);
